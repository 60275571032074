import React, { useState } from 'react'
import { isBrowser } from '../services/auth'
import { DebitOrderMandate } from '../components/shared'
import { Router } from '@reach/router'
import PrivateLayout from '../components/PrivateLayout'
import { navigate } from 'gatsby'

function DebitOrder() {
  const [open, setOpen] = useState(true)
  if (!isBrowser()) {
    return <div />
  } 

  let id = new URLSearchParams(window.location.search).get('id')
  if (id) {
    navigate(`/do/${id}`)
  }

  return <PrivateLayout showSideBar={false} selectedIndex={0}>
    <Router basepath="/do">
      <DebitOrderMandate path='/:id' open={open} setOpen={setOpen} />
    </Router>
  </PrivateLayout>

  // return <PageRedirect pageContext={{ redirect: `${PORTAL_URL}/do/${id}` }} />
}

export default DebitOrder
